import '../App.css';
//import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Header from '../components/Header';
import ProductCard from '../components/ProductCard';
import CustomInput from '../components/CustomInput';
import Styles from '../styles/styles';
import useFetch from '../customHook/useFetch';
import fetchFunc from '../customHook/fetchFunc';
import axiosFetch from '../customHook/axiosFetch';
import url from './url';
import { useSelector , useDispatch} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useLoginStatus from '../customHook/useLoginStatus';
import cookie from 'react-cookies';
import ScaleLoader from "react-spinners/ScaleLoader";
import { AiOutlineMail,AiFillLock } from "react-icons/ai";

const Login= ({redirect, setAccount, setUser, setStatus})=>{

const [email,setEmail]= useState("");
const [password,setPassword]= useState("");
const [buttonState, setButtonState] = useState("Login");

//navigation
const navigate = useNavigate();


//sen to redux store
//const storedUser = useSelector(state=>state.user);
/* redundant code
const actionType = "ADD_USER";
    const dispatch = useDispatch();
    const actionFunc = (payload)=>{
      const action={type:actionType, payload:payload};
      return action;
  }*/

  //fetch info

const body = {email,password}

    // const [data, setData] = useState(null);
    // const [isPending, setIsPending] = useState(true);
     const [loginError, setLoginError] = useState(null);


    const token = cookie.load('id');
    const [trigger, setTrigger] = useState(false);
      
    const{data, isPending, error} = useFetch(url("user/"+token),"GET",null,trigger);

    useEffect(()=>{
      if (data && data.status==="loggedIn"){
    setAccount && setAccount(data.account)
    setStatus && setStatus( data.status)
    setUser && setUser(data.user)
    navigate(redirect);
     }
    },[data])
      
   // const [userID, setUserID] = useState(null);

    //know loggin status
//const{status, waiting, statusError} = useLoginStatus(userID)

    
    const send = async(e)=>{
        e.preventDefault();
        setButtonState("Loading");
    // setUser( axiosFetch("https://39f5-41-217-100-12.ngrok.io/admin/login/",email,password)) ;
     let userID = await fetchFunc(url("auth/login"),"POST",body,true);
     //redundant
  //   dispatch(actionFunc({userID: await userID.user}));
   //  dispatch(actionFunc({firstname: await userID.firstname}));
   //  dispatch(actionFunc({status: "loggedIn"}));
     
     if(userID && userID.msg === "successful"){
//setTrigger(!trigger)
       navigate(redirect);
        //window.location.replace(redirect); // does not appear on history
    // window.location.reload();
     }
if(userID.msg !== "sucessful"){
setButtonState("Login")
    //console.log(userID.msg);
    setLoginError(userID.msg)
  }
     
    }

    

    return(
         <div>


    <div style={Styles.flexContainer}>
   <div style={Styles.authWrapper}>
        <form onSubmit={(e)=>{send(e)}}>
            <h3 style={Styles.textHeader}>Login</h3>
            <p>{loginError}</p>
          <div style={Styles.inputContainer}> 
          <label style={Styles.smallBlackBoldText}><AiOutlineMail size={20} color={"black"} /> Email</label> 
          <div style={Styles.inline}>
            
            <input onFocus={()=>{Styles.input.borderWidth=0}} className='input' 
           style={Styles.input2} type="email" placeholder="Enter email" 
           onChange={(e)=>{setEmail(e.currentTarget.value)}} />
           </div>
           </div>
            <div style={Styles.inputContainer}> 
            <label style={Styles.smallBlackBoldText}><AiFillLock size={20} color={"black"}/> Password</label> 
            <div>
              
              <input style={Styles.input2} type="password" placeholder="Enter password"  onChange={(e)=>setPassword(e.currentTarget.value)}/></div></div>
            <Link to="/forgotpassword">Forgot Password?</Link>
              <div style={{margin:10}}>  
              <button style={Styles.button} type="submit" > { buttonState ==="Loading" && <ScaleLoader color={"#E92C2C"} loading={true} cssOverride={Styles.override} size={150} />} 
              { buttonState ==="Login" && buttonState} 
              </button></div>
</form>
</div>
</div>


        </div>
    );

}

export default Login;