import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import ProductCard from '../components/ProductCard';
import CustomInput from '../components/CustomInput';
import Styles from '../styles/styles';
import useFetch from '../customHook/useFetch';
import fetchFunc from '../customHook/fetchFunc';
import axiosFetch from '../customHook/axiosFetch';
import url from './url';
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useLoginStatus from '../customHook/useLoginStatus';
import cookie from 'react-cookies';
import ScaleLoader from "react-spinners/ScaleLoader";


const Signup= ({lurl,redirect, setAccount, setUser, setStatus})=>{


const [firstname, setFirstname]= useState("");
const [lastname, setLastname] = useState("");
const [phone, setPhone] = useState("");    
const [email,setEmail]= useState("");
const [password,setPassword]= useState("");
const [cPassword,setCPassword]= useState("");
const [buttonState, setButtonState] = useState("Create Account");
const[errorSignup, setErrorSignup] = useState("");
const[verificationSent, setVerificationSent] = useState(false);
const[verificationmsg, setVerificationmsg] = useState("");
const[dataInput, setDataInput] = useState(true);

const [fetchError, setFetchError]= useState("")

//navigation
const navigate = useNavigate();


//sen to redux store
//const storedUser = useSelector(state=>state.user);
const actionType = "ADD_USER";
    const dispatch = useDispatch();
    const actionFunc = (payload)=>{
      const action={type:actionType, payload:payload};
      return action;
  }
  const token = cookie.load('id');
  //fetch info

const body = {email,password,firstname,lastname,phone, request:"login"}
   // const [userID, setUserID] = useState(null);

   const [trigger, setTrigger] = useState(false);
      
   const{data, isPending, error} = useFetch(url("user/"+token),"get",null,trigger);
     if (data && data.status==="loggedIn"){
    
        setAccount(data.account)
   setStatus( data.status)
   setUser(data.user)
   navigate(redirect);
    }

    //know loggin status
//const{status, waiting, statusError} = useLoginStatus(userID)

    
    const send = async(e)=>{
        e.preventDefault();
        setErrorSignup("")
        setButtonState("Loading");
    // setUser( axiosFetch("https://39f5-41-217-100-12.ngrok.io/admin/login/",email,password)) ;
    if (password !== "" && password === cPassword){
     let userID = await fetchFunc(url("auth/signup"),"POST",body,true);
    
     //dispatch(actionFunc({userID: await userID.user}));
    // dispatch(actionFunc({firstname: await userID.firstname}));
    // dispatch(actionFunc({status: "loggedIn"}));
     
     if(userID && userID.msg === "An activation link has been sent to you"){
        //setTrigger(!trigger) // does not appear on history
        setVerificationmsg(userID.msg)
        setDataInput(false)
        setVerificationSent(true)

        //navigate("/")
    // window.location.reload(); 
     }
if(userID && userID.msg !== "An activation link has been sent to you"){
setButtonState("Create Account")
    console.log(userID);
    setFetchError(userID)}
     
  }else{
    //if password doesnot match
   setErrorSignup("Passwords do not match")
setButtonState("Create Account")
    }

  }

    return(
         <div>


    <div style={Styles.flexContainer}>
   <div style={Styles.authWrapper}>
        <form onSubmit={(e)=>{send(e)}}>
            <h3 style={Styles.textHeader}>Create new account</h3>
            {dataInput && <div><p style={Styles.error}>{fetchError.firstname}</p>
            <div style={Styles.inputContainer}>
              <label style={Styles.smallBlackBoldText}>First Name</label>
              <div style={Styles.inline}>
            <input style={Styles.input2} type="input" placeholder="Please Insert first name" onChange={(e)=>{setFirstname(e.currentTarget.value)}} /></div></div>  
            <p>{fetchError.lastname}</p>
            
            <div style={Styles.inputContainer}>
              <label style={Styles.smallBlackBoldText}>Last Name</label>
              <div style={Styles.inline}> 
            <input style={Styles.input2} type="input" placeholder="Please Insert last name" onChange={(e)=>{setLastname(e.currentTarget.value)}} /></div></div>
            <p>{fetchError.phone}</p>
            <div style={Styles.inputContainer}>
              <label style={Styles.smallBlackBoldText}>Phone Number</label>
              <div style={Styles.inline}> 
            <input style={Styles.input2} type="phone" placeholder="Phone number" onChange={(e)=>{setPhone(e.currentTarget.value)}} /></div></div>
          
            <div style={Styles.inputContainer}>
            <p style={Styles.error}>{fetchError.email}</p>
              <label style={Styles.smallBlackBoldText}>Email</label>
              <div style={Styles.inline}> 
            <input style={Styles.input2} type="email" placeholder="Enter email" onChange={(e)=>{setEmail(e.currentTarget.value)}} /></div></div>
            
           
            <div style={Styles.inputContainer}>
              <label style={Styles.smallBlackBoldText}>Password</label>
              <div style={Styles.inline}> 
            <input style={Styles.input2} type="password" placeholder="Enter password"  onChange={(e)=>setPassword(e.currentTarget.value)}/></div> </div>
             <p style={Styles.error}>{errorSignup}</p>
             <div style={Styles.inputContainer}>
              <label style={Styles.smallBlackBoldText}>Confirm password</label>
              <div style={Styles.inline}> 
            <input style={Styles.input2} type="password" placeholder="confirm password"  onChange={(e)=>setCPassword(e.currentTarget.value)}/></div></div>

             
              <button style={Styles.button} type="submit" > { buttonState ==="Loading" && <ScaleLoader color={"#E92C2C"} loading={true} cssOverride={Styles.override} size={150} />} 
              { buttonState ==="Create Account" && buttonState} 
              </button>
</div>
    }

    {verificationSent && <did>
      <h5>{verificationmsg}</h5>
      </did>}
</form>
</div>
</div>


        </div>
    );

}

export default Signup;